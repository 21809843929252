import {MutableRefObject} from 'react'
import { Menu } from '@headlessui/react'
import { classnames } from 'tailwindcss-classnames'

import { Menu as MenuInf } from '@tilnet/lagaat-domain'
import MenuItem from './MenuItem'

type Close = {
    closePopover?: ( focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined ) => void
}

const itemStyle = classnames(
    "block",
    "text-white", 
    "font-light", 
    "text-lg", 
    "leading-none",
    "mb-5",
    "pb-5",
    "border-b", 
    "mr-5",
    "ml-14",
)

const itemSmStyle = classnames(
    "sm:mr-6", 
)

const itemMdStyle = classnames(
    "md:mr-8",
)

const itemLgStyle = classnames(
    "lg:inline-block", 
    "lg:text-black", 
    "lg:mb-0",
    "lg:pb-2",
    "lg:pl-6",
    "lg:border-b-0", 
    "lg:mr-0",
    "lg:ml-0",
    "lg:border-b",    
)

const itemXlStyle = classnames(
    "xl:pl-8"
)

const item2XlStyle = classnames(
    "2xl:pl-12"
)

const BaseMenu = ({closePopover, ...menu}: MenuInf & Close) => {

    return (
        <Menu>
            <>
                <Menu.Items static>

                    {Object.entries(menu).map(([name, menuItem]) => 
                        <MenuItem 
                            key={name} 
                            {...menuItem} 
                            closePopover={closePopover} 
                            className={`last:border-b-0 first:mt-8 lg:hover-bold lg:last:border-b lg:last:text-left lg:last:ml-0 lg:last:pl-0 lg:first:mt-0 ${classnames(
                                itemStyle,
                                itemSmStyle,
                                itemMdStyle,
                                itemLgStyle,
                                itemXlStyle,
                                item2XlStyle
                            )}`} 
                            activeClassName="text-bold"
                        />
                    )}

                </Menu.Items>   

            </>
       
        </Menu>
    )
}

export default BaseMenu